.Select-placeholder, .Select--single > .Select-control .Select-value {
  color: #4D4D4D;
}

.Select.is-focused:not(.is-open) > .Select-control {
  box-shadow: none !important;
}

.Select-input > input {
  color: #4D4D4D;
}
